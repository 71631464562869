<template>
  <div class="regulation">
    <div class="regulation__box">
      <h1>Regulamin portalu</h1>
      <a-divider />
      <div class="regulation__content">
        <regulation-text />
      </div>
    </div>
  </div>
</template>

<script>
import RegulationText from "@/components/RegulationText";

export default {
  name: "Regulation",
  components: { RegulationText },
  data() {
    return {
      checked: false,
    };
  },
};
</script>

<style lang="scss" scoped>
$regulation-box-width: $container-width;
$regulation-box-height: 646px;
$regulation-box-margin: 5vh;

.regulation {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: inherit;

  .regulation__box {
    background-color: white;
    width: $regulation-box-width;
    display: flex;
    flex-direction: column;
    padding: 24px;
    height: calc(
      100vh - #{$header-height} - #{$footer-height} - 2 *#{$regulation-box-margin}
    );

    @media (max-width: $regulation-box-width) {
      padding: 10px 16px 24px 16px;
      margin-top: 0;
      width: 100%;
      height: calc(100vh - #{$header-height} - #{$footer-height});
      overflow: visible;
    }
    
    h1 {
      font-size: 32px;
      font-weight: bold;
      color: $c1;
      margin-bottom: 0;
    }

    .regulation__content {
      overflow: auto;
      height: inherit;
    }
  }
}
</style>
