<template>
  <div class="profile">
    <h1>Profil pacjenta</h1>
    <a-form ref="refForm" :model="formModel" :rules="formRules">
      <h2>Dane pacjenta</h2>

      <div class="profile__container">
        <a-row class="profile__row">
          <a-col :lg="6" :md="6" :sm="12" :xs="12" class="profile__field-name"
            >Imię</a-col
          ><a-col
            :lg="18"
            :md="18"
            :sm="12"
            :xs="12"
            class="profile__value-wrapper"
            ><a-skeleton :paragraph="false" :loading="data == null" active
              ><span class="profile__value">{{
                data.firstName
              }}</span></a-skeleton
            >
          </a-col>
        </a-row>
        <a-row class="profile__row">
          <a-col :lg="6" :md="6" :sm="12" :xs="12" class="profile__field-name"
            >Nazwisko</a-col
          >
          <a-col
            :lg="18"
            :md="18"
            :sm="12"
            :xs="12"
            class="profile__value-wrapper"
            ><a-skeleton :paragraph="false" :loading="data === null" active
              ><span class="profile__value">{{
                data.lastName
              }}</span></a-skeleton
            >
          </a-col>
        </a-row>
        <a-row class="profile__row">
          <a-col :lg="6" :md="6" :sm="12" :xs="12" class="profile__field-name"
            >PESEL</a-col
          >

          <a-col
            :lg="18"
            :md="18"
            :sm="12"
            :xs="12"
            class="profile__value-wrapper"
          >
            <a-skeleton :paragraph="false" :loading="data === null" active
              ><span class="profile__value">{{ data.pesel }}</span></a-skeleton
            >
          </a-col>
        </a-row>
      </div>

      <h2>Dane kontaktowe</h2>

      <div class="profile__container">
        <a-row class="profile__row" type="flex">
          <a-col
            :lg="{ span: 6, order: 1 }"
            :md="{ span: 6, order: 1 }"
            :sm="{ span: 12, order: 1 }"
            :xs="{ span: 24, order: 1 }"
            class="profile__field-name"
            >Adres e-mail
          </a-col>
          <a-col
            :lg="{ span: 12, order: 2 }"
            :md="{ span: 12, order: 2 }"
            :sm="{ span: 12, order: 3 }"
            :xs="{ span: 24, order: 3 }"
            class="profile__value-wrapper"
          >
            <a-skeleton :paragraph="false" :loading="data === null" active>
              <div class="profile__value" v-if="toggleMode !== 'email'">
                {{ data.email }}
              </div>
              <a-form-item
                v-if="toggleMode === 'email'"
                has-feedback
                name="email"
              >
                <a-input
                  autocomplete="off"
                  v-model:value="this.formModel.email"
                >
                </a-input>
              </a-form-item>
            </a-skeleton>
          </a-col>
          <a-col
            :lg="{ span: 6, order: 3 }"
            :md="{ span: 6, order: 3 }"
            :sm="{ span: 12, order: 2 }"
            :xs="{ span: 24, order: 3 }"
            class="profile__actions-wrapper"
          >
            <a-skeleton
              :title="{ width: 90 }"
              :paragraph="false"
              :loading="data === null"
              active
            >
              <div v-if="toggleMode !== 'email'">
                <a-button type="link" @click="edit('email')">
                  <EditOutlined />
                  Edytuj
                </a-button>
              </div>

              <div v-if="toggleMode === 'email'">
                <a-button
                  type="link"
                  class="profile__save"
                  html-type="submit"
                  :disabled="!valid"
                  @click="updateUser('email')"
                  :loading="updatingUserData"
                >
                  Zapisz zmiany
                </a-button>
                <a-button type="link" @click="cancel" class="profile__cancel">
                  Anuluj
                </a-button>
              </div>
            </a-skeleton>
          </a-col>
        </a-row>
        <a-row class="profile__row" type="flex">
          <a-col
            :lg="{ span: 6, order: 1 }"
            :md="{ span: 6, order: 1 }"
            :sm="{ span: 12, order: 1 }"
            :xs="{ span: 24, order: 1 }"
            class="profile__field-name"
            >Numer telefonu
          </a-col>
          <a-col
            :lg="{ span: 12, order: 2 }"
            :md="{ span: 12, order: 2 }"
            :sm="{ span: 12, order: 3 }"
            :xs="{ span: 24, order: 3 }"
            class="profile__value-wrapper"
          >
            <a-skeleton :paragraph="false" :loading="data === null" active>
              <div class="profile__value" v-if="toggleMode !== 'phoneNumber'">
                {{ data.phoneNumber }}
              </div>
              <a-form-item
                v-if="toggleMode === 'phoneNumber'"
                has-feedback
                name="phoneNumber"
              >
                <a-input
                  autocomplete="off"
                  v-model:value="this.formModel.phoneNumber"
                >
                </a-input>
              </a-form-item>
            </a-skeleton>
          </a-col>
          <a-col
            :lg="{ span: 6, order: 3 }"
            :md="{ span: 6, order: 3 }"
            :sm="{ span: 12, order: 2 }"
            :xs="{ span: 24, order: 3 }"
            class="profile__actions-wrapper"
          >
            <a-skeleton
              :title="{ width: 90 }"
              :paragraph="false"
              :loading="data === null"
              active
            >
              <div v-if="toggleMode === 'phoneNumber'">
                <a-button
                  type="link"
                  class="profile__save"
                  html-type="submit"
                  :loading="updatingUserData"
                  @click="updateUser('phoneNumber')"
                  :disabled="!valid"
                >
                  Zapisz zmiany
                </a-button>
                <a-button type="link" @click="cancel" class="profile__cancel">
                  Anuluj
                </a-button>
              </div>
              <div v-if="toggleMode !== 'phoneNumber'">
                <a-button type="link" @click="edit('phoneNumber')">
                  <EditOutlined />
                  Edytuj
                </a-button>
              </div>
            </a-skeleton>
          </a-col>
        </a-row>
        <a-row class="profile__row" type="flex">
          <a-col
            :lg="{ span: 6, order: 1 }"
            :md="{ span: 6, order: 1 }"
            :sm="{ span: 12, order: 1 }"
            :xs="{ span: 24, order: 1 }"
            class="profile__field-name"
          >
            Adres kontaktowy
          </a-col>
          <a-col
            :lg="{ span: 12, order: 2 }"
            :md="{ span: 12, order: 2 }"
            :sm="{ span: 12, order: 3 }"
            :xs="{ span: 24, order: 3 }"
            class="profile__value-wrapper"
          >
            <a-skeleton :paragraph="false" :loading="data === null" active>
              <div class="profile__value" v-if="toggleMode !== 'address'">
                {{ data.address }}
              </div>
              <a-form-item
                v-if="toggleMode === 'address'"
                has-feedback
                name="address"
              >
                <a-input
                  autocomplete="off"
                  v-model:value="this.formModel.address"
                >
                </a-input>
              </a-form-item>
            </a-skeleton>
          </a-col>

          <a-col
            :lg="{ span: 6, order: 3 }"
            :md="{ span: 6, order: 3 }"
            :sm="{ span: 12, order: 2 }"
            :xs="{ span: 24, order: 3 }"
            class="profile__actions-wrapper"
          >
            <a-skeleton
              :title="{ width: 90 }"
              :paragraph="false"
              :loading="data === null"
              active
            >
              <div v-if="toggleMode !== 'address'">
                <a-button type="link" @click="edit('address')">
                  <EditOutlined /> Edytuj
                </a-button>
              </div>
              <div v-if="toggleMode === 'address'">
                <a-button
                  type="link"
                  class="profile__save"
                  html-type="submit"
                  :loading="updatingUserData"
                  @click="updateUser('address')"
                  :disabled="!valid"
                >
                  Zapisz zmiany
                </a-button>
                <a-button type="link" @click="cancel" class="profile__cancel">
                  Anuluj
                </a-button>
              </div>
            </a-skeleton>
          </a-col>
        </a-row>
      </div>
    </a-form>
  </div>
</template>

<script>
import { EditOutlined } from "@ant-design/icons-vue";

export default {
  name: "Profile",
  components: {
    EditOutlined,
  },
  data() {
    return {
      valid: false,
      formModel: {
        address: "",
        email: "",
        phoneNumber: "",
      },
      updatingUserData: false,
      data: null,
      formRules: {
        email: [
          {
            required: true,
            message: "Podaj adres email",
          },
        ],
        phoneNumber: [
          {
            required: true,
            pattern: new RegExp("^[+][0-9]{11}$"),
            message: "Podaj prawidłowy numer telefonu",
          },
        ],
        address: [
          {
            required: true,
            message: "Podaj adres kontaktowy",
          },
        ],
      },
      toggleMode: "",
    };
  },
  watch: {
    formModel: {
      async handler() {
        this.$refs.refForm
          .validate()
          .then(() => {
            this.valid = true;
          })
          .catch(() => {
            this.valid = false;
          });
      },
      deep: true,
    },
  },
  mounted() {
    this.getUserProfile();
  },
  methods: {
    getUserProfile() {
      this.$http
        .get(`patient-portal/api/patient-profile`)
        .then((res) => {
          this.data = res.data;
        })
        .catch(() => {
          this.$notification.open({
            message: "Wystąpił błąd podczas wczytywania danych pacjenta",
            class: "error",
          });
        });
    },
    updateUser(field) {
      this.updatingUserData = true;
      this.$http
        .put(`patient-portal/api/patient-profile`, {
          [field]: this.formModel[field],
        })
        .then((res) => {
          this.updatingUserData = false;
          this.data[field] = res.data[field];
          this.$notification.open({
            message: "Zmiany zostały zapisane pomyślnie",
            class: "success",
          });

          this.toggleMode = "";
        })
        .catch(() => {
          this.$notification.open({
            message: "Wystąpił błąd podczas akutalizowania danych",
            class: "error",
          });
        });
    },
    edit(field) {
      this.toggleMode = field;
      this.formModel[field] = this.data[field];
    },
    cancel() {
      this.toggleMode = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  padding: 24px 10px;
  max-width: $container-width;
  margin: 0 auto;

  h2 {
    margin: 31px 0 25px 0;
  }

  .profile__container {
    .profile__row {
      background-color: white;
      padding: 20.5px 16px;
      border-left: 1px solid $g4;
      border-right: 1px solid $g4;

      &:first-child,
      &:last-child {
        border: 1px solid $g4;
      }

      .profile__value-wrapper {
        .profile__value {
          display: flex;
          min-height: 40px;
        }

        ::v-deep(.ant-form-item) {
          margin-bottom: 0;
        }
      }

      .profile__actions-wrapper {
        display: flex;
        justify-content: flex-end;
        color: $c4;

        ::v-deep(.ant-skeleton-title) {
          float: right;
        }

        .profile__save {
          margin-right: 16px;
          color: $c1;
        }

        .profile__cancel {
          color: $r1;
        }
      }

      .profile__field-name {
        display: flex;

        font-weight: bold;
      }
    }
  }
}
</style>
